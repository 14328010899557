.App {
  text-align: center;
}

@media (max-width: 767px) {
  /* HIDDEN ON SMALL SCREENS */
  .hidden-on-small {
    display: none !important; /* Use !important to override other styles */
  }
}

@media (min-width: 768px) {
  /* ONLY VISIBLE ON SMALL SCREENS */
  .block-on-small {
    display: none !important; /* Use !important to override other styles */
  }
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 12px;
}
.dark ::-webkit-scrollbar-thumb {
  background-color: #2c3353;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #d9dae1;
  border-radius: 10px;
}
.dark-scrollbar::-webkit-scrollbar-track {
  background-color: #0E1022;
  border: 1px solid #0E1022;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.light-scrollbar::-webkit-scrollbar-track {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
select:not([size]) {
  padding-right: 1.5rem;
}

@layer style-classes {
  .lgb-title {
    @apply text-2xl font-semibold dark:text-white;
  }
  .lgb-description {
    @apply text-base opacity-70 dark:text-white;
  }
  .lgb-subheading {
    @apply text-xl font-semibold dark:text-white;
  }
  .lgb-input-error {
    @apply border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500;
  }
}

.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
